module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[null],"gatsbyRemarkPlugins":[{"resolve":"/vercel/path0/_layouts/node_modules/gatsby-remark-wiki-link","id":"af50a6fa-693e-5e28-abb3-03fcfd1d4112","name":"gatsby-remark-wiki-link","version":"0.4.0","modulePath":"/vercel/path0/_layouts/node_modules/gatsby-remark-wiki-link/lib/index.js","pluginOptions":{"plugins":[],"stripBrackets":false,"stripDefinitionExts":[".md",".mdx"]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/vercel/path0/_layouts/node_modules/gatsby-remark-double-parenthesis-link","id":"7ee21003-1f07-5368-b50c-1c8775279826","name":"gatsby-remark-double-parenthesis-link","version":"0.1.5","modulePath":"/vercel/path0/_layouts/node_modules/gatsby-remark-double-parenthesis-link/lib/index.js","module":{},"pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/vercel/path0/_layouts/node_modules/gatsby-remark-copy-linked-files","id":"5df53bde-bc1c-5431-88ae-7e6761d2d83a","name":"gatsby-remark-copy-linked-files","version":"4.11.0","modulePath":"/vercel/path0/_layouts/node_modules/gatsby-remark-copy-linked-files/index.js","pluginOptions":{"plugins":[],"ignoreFileExtensions":["md","mdx"]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/vercel/path0/_layouts/node_modules/gatsby-remark-prismjs","id":"5023d14b-37aa-5851-92fb-c14b1e804e35","name":"gatsby-remark-prismjs","version":"5.11.0","modulePath":"/vercel/path0/_layouts/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[],"noInlineHighlight":true},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"rehypePlugins":[null],"defaultLayouts":{},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/vercel/path0/_layouts","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-tocbot/gatsby-browser.js'),
      options: {"plugins":[],"tocbotOptions":{"contentSelector":".topic-layout__content","collapseDepth":5,"scrollContainer":".topic-layout__content"}},
    },{
      plugin: require('../node_modules/gatsby-theme-kb/gatsby-browser.js'),
      options: {"plugins":[],"rootNote":"/readme","contentPath":"/vercel/path0/_layouts/..","ignore":["**/_layouts/**","**/.git/**","**/.github/**","**/.vscode/**","**/.cache/**","**/templates/**","**/.foam/**"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
